<template>
  <div>
    <div class="menu-header">
      <a-icon class="arrow" type="arrow-left" @click="$router.go(-1)" />
      <div class="title">知情签署文件</div>
    </div>
    <a-row>
      <a-col :span="24" class="view-over">
        <div
          class="view-wrap"
          :class="{'view-active': i==current}"
          v-for="(item, i) in allList"
          :key="i"
        >
          <div class="pdf-view" v-if="item.fileType === pdfText" @click="itemTap(item, i)">
            <img style="width:20px;" src="../../assets/pdf.png"/>
            <div class="pdf-view-text">PDF文件</div>
          </div>
          <div class="img-view" v-else @click="itemTap(item, i, 'img')">
            <img style="width:100%;" :src="item.fileUrl"/>
          </div>
        </div>
      </a-col>
      <a-col :span="24">
        <div v-show="pdfShow" id="pdf" class="pdf-wrap">
          <div id="demo"></div>
        </div>
        <div v-show="!pdfShow" class="img-wrap">
          <img style="width:100%;" :src="imgUrl"/>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
import { getApplyPaper } from '@/api/consent/consent'

export default {
  data () {
    return {
      proId: '',
      current: 0,
      pdfh5: null,
      newPdfUrl: '',
      allList: [],
      imgUrl: '',
      pdfShow: true,
      pdfText: 'pdf'
    }
  },
  created () {
    const pd = localStorage.getItem('CURRENT_PROID')
    if (pd) {
      this.proId = parseInt(pd)
    }
  },
  mounted () {
    this.handleSee()
  },
  methods: {
    handleSee () {
      if (this.$route.query.file) {
        this.allList = [{
          fileUrl: process.env.VUE_APP_BASE_IMAGE + this.$route.query.file,
          fileType: 'pdf'
        }]
        this.getpdfPage()
        return
      }
      getApplyPaper({ applyId: this.$route.query.id }).then(res => {
        this.allList = res
        this.allList.forEach(item => {
          item.fileUrl = process.env.VUE_APP_BASE_IMAGE + (item.fileUrl ? item.fileUrl : item.response.data)
          item.fileType = item.fileUrl ? item.fileUrl.substring(item.fileUrl.lastIndexOf('.') + 1) : item.response.data.substring(item.response.data.lastIndexOf('.') + 1)
        })
        if (this.allList[0].fileType === this.pdfText) {
          this.getpdfPage()
        } else {
          this.getpdfPage(this.allList[0])
        }
      })
    },
    getpdfPage (e) {
      if (!this.allList.length) {
        this.pdfShow = false
        return
      }
      this.$nextTick(() => {
        if (!e) {
          this.pdfShow = true
          this.newPdfUrl = 'demo.pdf'
          this.pdfh5 = new Pdfh5('#demo', {
            pdfurl: this.allList.length > 0 ? this.allList[this.current].fileUrl : null,
            lazy: true,
            cMapUrl: 'https://unpkg.com/pdfjs-dist@2.0.943/cmaps/' // 插件默认的地址证书过期了
          })
        } else {
          this.pdfShow = false
          this.imgUrl = e.fileUrl
        }
      }, 200)
    },
    handleChange ({ file, fileList }) {
      if (file.status === 'done') {
        const { response } = file
        if (response && response.status === '1' && response.data) {
          this.allList = [...this.allList, file]
          this.allList.forEach(item => {
            item.fileUrl = process.env.VUE_APP_BASE_IMAGE + item.response.data
            item.fileType = item.response.data.substring(item.response.data.lastIndexOf('.') + 1)
          })
          // 上传大于1后不渲染
          if (this.allList.length > 1) return
          if (file.fileType !== this.pdfText) {
            this.getpdfPage(file)
          } else {
            this.getpdfPage()
          }
        } else {
          this.allList = []
          this.$message.error(response && response.detail || '网络超时，请稍后再试')
        }
      }
    },
    itemTap (item, i, type) {
      if (this.current === i) return
      this.current = i
      if (type) {
        this.getpdfPage(item)
      } else {
        this.getpdfPage()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.pdf-wrap{
  width:100%;
  height:100%;
}
.img-wrap{
  width:100%;
  height:100%;
  overflow-y: scroll;
}
.view-over {
  width: 100%;
  overflow-x: scroll;
  padding: 30px 0 10px;
}
.view-wrap {
  width: 100px;
  padding:5px 10px;
  display: inline-block;
  vertical-align: middle;
  .pdf-view {
    width: 100%;
    height:100px;
    overflow: hidden;
    border: 1px solid #eee;
    text-align: center;
    padding-top: 30%;
    box-sizing: border-box;
    background: #fff;
    .anticon {
      font-size: 20px;
    }
    &-text {
      font-size: 12px;
      color: #4D5C6F;
      margin-top: 6px;
    }
  }
  .img-view {
    width: 100%;
    height:100px;
    overflow: hidden;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
}
.view-active {
  .pdf-view {
    border: 1px solid #43D8D2;
    outline: 1px solid #43D8D2;
  }
  .img-view {
    border: 1px solid #43D8D2;
    outline: 1px solid #43D8D2;
  }
}
</style>
